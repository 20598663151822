.about {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-position: center;
    background-image: url('../assets/background-blue.jpg');
}

@media (max-width: 500px) {
    .about {
      background-image: url('../assets/background-blue-smartphone.jpg');
    }
  }

.about h1{
    text-align: center;
    font-size: 70px;
    color: rgb(0,9,128);
    margin: 0;
    margin-bottom: 100px;
}

@media screen and (max-width: 350px) {
    .about h1 {
      font-size: 35px;
    }
  }

.about p{
    font-size: 30px;
    text-align: center;
}

.about-container {
    padding-top: 200px;
    background-color: white;
    margin-bottom: 0;
}  

.about-info-container {
    background-color: white;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 100px;
}

@media screen and (max-width: 350px) {
    .about-info-container {
        padding-left: 0;
        padding-right: 0;
    }
  }