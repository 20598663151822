.services-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns with equal width */
    gap: 0; /* No gap between the cards */
    background-color: rgb(2, 8, 102);
    width: 100%;
}

/* Media query for screens with a width less than 1000px */
@media screen and (max-width: 1000px) {
    .services-container {
        grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
    }

    /* Nested media query for screens with a width less than 530px */
    @media screen and (max-width: 530px) {
        .services-container {
            grid-template-columns: repeat(1, 1fr); /* 1 column */
        }
    }
}


.service-card {
    /* Remaining styles */
    display: flex;
    flex-direction: column; /* Change to column layout */
    justify-content: center; /* Vertically center content */
    align-items: center; /* Horizontally center content */
    cursor: pointer;
    transition: transform 0.8s;
    width: 100%;
}


/* .service-card:hover {
    transform: scale(1.05);
} */

.front {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    backface-visibility: hidden;
    background-color: rgb(0, 9, 128);
}

.back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    backface-visibility: hidden;
    background-color: rgb(194,125,106);
}


.service-title {
    margin-top: 10px;
    padding-bottom: 50px;
    color: white;
}

.service-description {
    padding: 10px;
    color: white;
}

.service-icon {
    margin-bottom: 100px;
    font-size: 50px;
}

.service-link {
    padding-top: 10px;
    color: black;
    text-decoration: none;
    font-size: 25px;
}
