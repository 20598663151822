body {
  margin: 0;
  padding: 0;
}

.survey-container {
  text-align: center;
  margin: 50px auto;
  width: 100%;
  font-family: Arial, sans-serif;
}


.form {
  margin: 0 auto;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f5;
  padding: 20px;
}