.reservation-system h2,
.reservation-system p,
.reservation-system input,
.reservation-system button {
    font-family: 'Roboto', sans-serif;
}

.reservation-system-header,
.reservation-system-content,
.reservation-system-info-container,
.reservation-system-form-container {
    margin-top: 15px;
}


.reservation-system {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #dedede;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
}

.reservation-system-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 100%;
    margin-bottom: 20px;
    /* Add margin below the header */
}

.reservation-system form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #e1e5f8;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    /* Ensure padding is included in width calculation */
}

.reservation-system input,
textarea {
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    /* Ensure padding is included in width calculation */
}

.reservation-system button {
    margin: 10px 0;
    padding: 10px 20px;
    background-color: #4f6cd4;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    color: white;
}

.reservation-system button:hover {
    background-color: #216af1;
}

.reservation-system-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* Creates three columns */
    gap: 20px;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    /* Center items horizontally */
}

.reservation-system-info-container,
.reservation-system-form-container {
    background: #fafaff;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    backdrop-filter: blur(40px);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    flex: 1;
    min-width: 300px;
    /* Ensures containers don't shrink too much */
    box-sizing: border-box;
    /* Ensure padding is included in width calculation */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center content horizontally */
    transition: transform 0.3s, box-shadow 0.3s;
    max-width: 100%;
    /* Ensure containers take full width */
}

.reservation-system-info-container h2,
.reservation-system-form-container h2 {
    margin: 0;
    padding: 10px 0;
    font-size: 1.5em;
    color: black;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.reservation-system-info-container p {
    font-size: 1em;
    color: #151515;
    line-height: 1.6;
    margin: 10px 0;
}

li {
    color: #151515;
}

#WhyNow {
    order: 2;
}

#WhyDoIt {
    order: 3;
}

.reservation-system-form-container {
    order: 1;
    /* Default order for form container */
}

.reservation-system-info-container:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.reservation-system .logo {
    width: 150px;
    margin: 10px;
    /* Add margin around the logo */
    position: relative;
    z-index: 10;
    /* Ensure logo is on top */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .reservation-system-content {
        display: flex;
        flex-direction: column;
        /* Stack items vertically */
        align-items: center;
        /* Center items */
    }

    .reservation-system-info-container,
    .reservation-system-form-container {
        max-width: 100%;
        /* Ensure containers take full width */
        margin: 10px 0;
        /* Adjust margin for vertical stacking */
    }

    .reservation-system-form-container {
        order: 2;
        /* Change order for form container */
    }

    #WhyNow {
        order: 1;
        /* Change order for info container */
    }

    #WhyDoIt {
        order: 3;
        /* Change order for info container */
    }
}