.project-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 800px;
    margin: 40px auto;
    background-color: #bbb6b6;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .project-detail-image {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .project-detail-name {
    margin-top: 20px;
    font-size: 2em;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  
  /* Additional styles for other product details you might add later */
  .project-detail-description {
    margin-top: 15px;
    font-size: 1em;
    color: #666;
    text-align: justify;
    line-height: 1.6;
  }
  