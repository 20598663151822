.samples {
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-position: center;
    background-image: url('../assets/background-blue.jpg');
}

@media (max-width: 500px) {
    .samples {
      background-image: url('../assets/background-blue-smartphone.jpg');
    }
  }

.samples .invis-container{
    height: 600px;
}

.sample-display-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 50px;
}

.samples h2 {
    color: rgb(0, 9, 128);
    font-size: 70px;
}

@media screen and (max-width: 350px) {
    .samples h2 {
      font-size: 35px;
    }
  }

.samples .heading {
    color: rgb(0, 9, 128);
}

.samples h2::after {
    background-color: transparent;
}

.samples .sample-display-container .centered {
    text-align: center;
}

.centered {
    width: 100%;
}

.helper-container {
    text-align: center;
    background-color: white;
    padding-top: 50px;
}

.sample-page-container {
    width: 100vw;
    height: 100vh;
}