.contact {
  padding-top: 100px;
  padding-bottom: 100px;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; 
  background-position: center;
  background-image: url('../assets/background-blue.jpg');
}


@media (max-width: 500px) {
  .contact {
    background-image: url('../assets/background-blue-smartphone.jpg');
  }
}

.contact-container {
  padding: 20px; /* Adjust the padding as needed */
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px; /* Maximum width of the container */
  background-color: #d0d0d0; /* Darker shade of the home page color */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  margin-top: 80px;
  margin-bottom: 20px;
  padding-bottom: 30px;
  border-color: rgb(0, 9, 128);
  border-style: solid;
}

.contact-container h1{
  text-align: center;
  color: black;
}

.contact-container p{
  color: black;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-form label {
  font-weight: bold;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: calc(100% - 20px); /* Subtract the total padding from the width */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-color: #000000;
  border-style: solid;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form textarea:focus {
border-color: rgb(0, 9, 128);
outline: none; /* Remove the default focus outline if needed */
box-shadow: 0 0 5px rgba(0, 9, 128, 0.5); /* Optional: Add a subtle shadow on focus */
}

.contact-form button {
  background-color: rgb(0, 9, 128); /* Brand color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: rgb(1, 7, 95); /* Darker shade on hover */
}

/* Responsive design */
@media (max-width: 768px) {
  .contact-container {
    padding: 10px;
  }

  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: calc(100% - 20px); /* Adjust the width on smaller screens */
  }
}

/* The rest of your styles... */
