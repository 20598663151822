@import url('https://fonts.googleapis.com/css2?family=Bigelow+Rules&family=Encode+Sans+Condensed:wght@300&family=Ubuntu&display=swap');

.video-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-wrapper {
  position: absolute;
  text-align: center;
  color: #ffffff; /* Set the text color */
  /* Add more styling as needed */
}

.text-wrapper p {
  color: white;
  font-size: 7rem; /* Set the font size */
  font-family: 'Bigelow Rules', serif;
  font-family: 'Encode Sans Condensed', sans-serif;
  font-family: 'Ubuntu', sans-serif;
}

.icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-container img {
  /* Make the icon smaller */
  width: 150px;
  height: 150px;
}

@media screen and (max-width: 800px) {
  .icon-container img {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 480px) {
  .icon-container img {
    width: 60px;
    height: 60px;
  }
}