body {
  /* background-color: #181818; */
  margin: 0;
  padding: 0;
  /* Apply background image to the body */
  /* Specify a fallback background color */
  font-family: "Josefin Sans", sans-serif;
}

.home {
  display: -webkit-box;
  display: -ms-flexbox;
  height: 100vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url('../assets/background-blue.jpg');
  display: flex;
  flex-direction: column;
  align-items: center;
  /* This will center the children horizontally */
  /* color: #ffffff; */
  color: rgb(0, 9, 128);
  line-height: 1.6;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;

}

@supports (-webkit-touch-callout: none) {
  .home {
    background-attachment: scroll;
  }
}

/* 
@media (max-width: 500px) {
  .home {
    background: url(../assets/background-blue-smartphone-upscaled.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-position: center;
  }
} */

.golden-container {
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
  /* border-color: #8a5a00; */
  /* border-style: solid; */
}

.centered-header {
  text-align: center;
}

p {
  /* color: #ffffff; */
  color: rgb(0, 9, 128);
  font-size: 1.1rem;
  margin: 1em 0;
  text-rendering: optimizeSpeed;
}


h2 {
  /* color: #ffffff; */
  color: rgb(0, 9, 128);
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  text-rendering: optimizeLegibility;
  position: relative;
  /* Needed for absolute positioning of the pseudo-element */
  display: inline-block;
  /* Ensures the line is only as wide as the text */
  margin-bottom: 1em;
  /* Space below the heading */
}

h2::after {
  content: '';
  display: block;
  width: 100%;
  /* Width of the decorative line */
  height: 5px;
  /* Increased thickness of the line */
  /* background-color: #8a5a00; */
  background-color: rgb(0, 9, 128);
  position: absolute;
  left: 0;
  bottom: -6px;
  /* Adjusted for the new thickness */
  border-radius: 2.5px;
  /* Half of the height to make the ends fully rounded */
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 0.5em 0;
  padding-left: 1em;
  text-indent: -0.7em;
}

.dashed-list li::before {
  content: "— ";
  /* Keep the dash here */
  /* color: #8a5a00; */
  color: rgb(0, 9, 128);
  /* ... other styles ... */
}

.animate-on-scroll {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.animate-on-scroll.animate {
  opacity: 1;
  transform: translateY(0);
}

a {
  /* color: #8a5a00; */
  color: rgb(0, 9, 128);
}

.image-content {
  flex: 1;
  margin-top: 40px;
}


@media (max-width: 1083px) {
  img {
    width: 90vw;
    /* Ensure the image doesn't exceed its original size */
    /* height: auto; */
    display: block;
    /* Remove extra space below the image */
    margin: auto;
    /* Center the image horizontally */
    padding: auto;
    image-rendering: optimizeQuality;
  }

  .services-content {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1083px) {
  img {
    width: 520px;
    /* Ensure the image doesn't exceed its original size */
    /* height: auto; */
    display: block;
    /* Remove extra space below the image */
    margin: auto;
    /* Center the image horizontally */
    padding: auto;
    image-rendering: optimizeQuality;
  }

  .services-content {
    display: flex;
    flex-direction: row;
  }
}

.text-wrapper p {
  /* Your styles for paragraphs inside text-wrapper */
  /* For example: */
  font-size: 30px;
  /* color: white; */
  color: rgb(0, 9, 128);
  line-height: 0;
  margin: 0;
  /* Add more styles as needed */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 41, 41, 0.8);
  /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Set a high z-index to ensure it's above other elements */
}

.image-content-icons img {
  width: 100px;
  height: 100px;
  margin-top: 30px;
}

.image-container-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* Center the images horizontally */
  align-items: center;
  gap: 50px;
  /* Add space between the images */
  margin-bottom: 40px;
}

.image-content-icons {
  margin-right: 0;
  /* Remove right margin */
  border-radius: 10px;
  width: 400px;
  height: 250px;
  /* box-shadow: 0 0 10px rgba(255, 215, 0, 0.5);  */
  box-shadow: 0 0 10px rgb(0, 9, 128);
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  /* Center text horizontally */
}

@keyframes floatAnimation {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.image-content-icons:hover {
  animation: floatAnimation 1s infinite;
  /* 1s duration, infinite loop */
}

.custom-font-1 p {
  font-size: 30px;
}

.custom-font-2 {
  /* margin-top: 100px; */
  /* margin-bottom: 100px; */
}

.custom-font-2 p {
  font-size: 30px;
  color: rgb(194, 125, 106);
}

.custom-font-3 {
  padding-top: 60px;
}


.custom-font-4 h2 {
  font-size: 30px;
}

.custom-font-4 p,
li {
  color: white;
}

.custom-font-4 h2::after {
  background-color: transparent;
}

.custom-font-5 {
  font-size: 30px;
  text-decoration: none;
  text-align: center;
}

.container-1 {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #EFEFEF;
}

.container-1 p {
  padding-left: 80px;
  padding-right: 80px;
  margin-bottom: 0;
  text-align: center;
}


@media screen and (min-width: 700px) {
  .container-1 p {
    margin-left: 100px;
    margin-right: 100px;
  }
}

.container-2 {
  width: 100%;
  background-color: rgb(0, 9, 128);
  color: white;
  /* margin-top: 150px; */
}

.container-2 p,
h2 {
  color: white;
}

.container-2 h2 {
  color: white;
  font-size: 50px;
}




.container-2 h2::after {
  background-color: transparent;
}

.container-3 {
  width: 100%;
  background-color: rgb(33, 33, 33);
}

.container-4 {
  background-color: rgb(0, 9, 128);
  color: white;
  /* margin-top: 150px; */
  padding-bottom: 50px;
  padding-left: 100px;
  padding-right: 100px;
}

@media screen and (max-width: 500px) {
  .container-4 {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 340px) {
  .container-4 {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
}

.container-4 p,
h2 {
  color: white;
}

.container-4 h2 {
  color: white;
  font-size: 70px;
}

@media screen and (max-width: 580px) {
  .container-4 h2 {
    font-size: 50px;
  }
}

.container-4 h2::after {
  background-color: transparent;
}

.sample-container {
  background-color: white;
  padding-bottom: 50px;
}

.sample-container h2 {
  color: rgb(0, 9, 128);
  font-size: 70px;
}

@media screen and (max-width: 580px) {
  .sample-container h2 {
    font-size: 50px;
  }
}

.sample-container h2::after {
  background-color: transparent;
}

.invis-container {
  height: 400px;
}

.hermes-container {
  display: flex;
  justify-content: flex-start;
}

.hermes {
  /* color: rgb(255, 255, 255); */
  color: rgb(0, 9, 128);
  font-size: 2.5rem;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  position: relative;
  /* Needed for absolute positioning of the pseudo-element */
  display: inline-block;
  /* Ensures the line is only as wide as the text */
  margin-left: 200px;
}

.hermes::after {
  content: '';
  display: block;
  width: 50px;
  /* Width of the decorative line */
  height: 5px;
  /* Increased thickness of the line */
  /* background-color: #8a5a00;  */
  background-color: rgb(0, 9, 128);
  position: absolute;
  left: 0;
  bottom: 40px;
  /* Adjusted for the new thickness */
  border-radius: 2.5px;
  /* Half of the height to make the ends fully rounded */
}

@media screen and (max-width: 730px) {
  .hermes {
    margin-left: 0px;
    margin-right: 0px;
  }

  .hermes-container {
    display: flex;
    justify-content: center;
  }

  .hermes::after {
    left: 27%
  }
}

.view-container {
  display: flex;
  justify-content: flex-end;
}

.view {
  color: rgb(0, 9, 128);
  font-size: 2.5rem;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  /* margin-bottom: 1em; */
  position: relative;
  /* Add position relative */
  margin-right: 200px;
}

.view::after {
  content: '';
  display: block;
  width: 50px;
  height: 5px;
  background-color: rgb(0, 9, 128);
  position: absolute;
  bottom: 40px;
  /* Adjusted for the new thickness */
  right: 0;
  /* Position it at the left edge of the container */
  border-radius: 2.5px;
  /* Half of the height to make the ends fully rounded */
}

@media screen and (max-width: 730px) {
  .view {
    margin-right: 0;
    margin-left: 0;
  }

  .view-container {
    display: flex;
    justify-content: center;
  }

  .view::after {
    right: 35%;
    /* Position it at the left edge of the container */
  }
}

.our-services {
  background-color: rgb(0, 9, 128);
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  text-align: center;
  position: relative;
  /* Add position relative */
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 0;
}

.text-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

/* Media query for screens with a width lower than 835px */
@media screen and (max-width: 835px) {
  .text-content {
    grid-template-columns: 1fr;
    /* Change to one column */
  }
}


.question-container {
  padding: 10px;
}

.question-title {
  font-weight: bold;
  font-size: 35px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 100px;
}

/* Media query for screens with a width lower than 870px */
@media screen and (max-width: 1080px) {
  .grid-container {
    grid-template-columns: 1fr;
    /* Change to one column */
    padding: 20px;
    /* Adjust padding for smaller screens */
  }
}

.text-column {
  padding-top: 120px;
}

.form-column {
  background-color: rgb(255, 255, 255);
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 50px;
}

@media screen and (max-width: 350px) {
  .form-column {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.form-column h2 {
  color: rgb(0, 9, 128);
}

.form-column h2::after {
  background-color: transparent;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
}

input[type="text"],
input[type="email"],
textarea {
  width: calc(100% - 16px);
  /* Adjust the width to fit your design */
  border: none;
  border-bottom: 1px solid #ccc;
  /* Add a bottom border */
  padding: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s;
  /* Add transition effect */
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  border-bottom-color: transparent;
  /* Remove the bottom border on focus */
}

button {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background-color: #007bff;
  /* Change button color */
  color: #fff;
  /* Change button text color */
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: rgb(0, 9, 128);
  /* Change button color on hover */
}



@media screen and (min-width: 1400px) {
  .sample-display-container>*:nth-child(even) {
    margin-left: auto;
    /* Push even elements to the right */
    text-align: right;
    /* Align text to the right for even elements */
  }

  .sample-display-container>*:nth-child(odd) {
    text-align: left;
    /* Align text to the left for odd elements */
  }
}

@media screen and (max-width: 1120px) {
  .sample-display-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align items to the left initially */
    padding-left: 0;
    /* Add padding to the container */
    padding-right: 0;
    /* Add padding to the container */
  }
}

.sample-item {
  display: grid;
  grid-template-columns: auto auto;
  gap: 0px;
  /* Initial gap value */
  padding-left: 200px;
  padding-right: 200px;
}

/* Media query for screens with a width lower than 1060px */
@media screen and (max-width: 1650px) {
  .sample-item {
    padding-left: 100px;
    padding-right: 100px;
  }
}

/* Media query for screens with a width lower than 1060px */
@media screen and (max-width: 1500px) {
  .sample-item {
    padding-left: 50px;
    padding-right: 50px;
  }
}


/* Media query for screens with a width lower than 1060px */
@media screen and (max-width: 1400px) {
  .sample-item {
    width: 100%;
    grid-template-columns: auto;
    /* Adjusted gap value for smaller screens */
    padding-left: 0;
    padding-right: 0;
  }
}

.container-iphone {
  margin-left: 300px;
}

/* Media query for screens with a width lower than 1060px */
@media screen and (max-width: 1640px) {
  .container-iphone {
    margin-left: 200px;
    /* Adjusted gap value for smaller screens */
  }
}

@media screen and (max-width: 1400px) {
  .container-iphone {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.container-ipad {
  margin-right: 100px;
}

/* Media query for screens with a width lower than 1060px */
@media screen and (max-width: 1640px) {
  .container-ipad {
    margin-right: 200px;
    /* Adjusted gap value for smaller screens */
  }

}

@media screen and (max-width: 1400px) {
  .container-ipad {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1400px) {
  .container-imac {
    margin-left: auto;
    margin-right: auto;
  }
}

.container-iphone,
.container-imac,
.container-ipad {}

.heading {
  font-size: 3rem;
  /* Increased font size */
  text-align: center;
}

.line {
  width: 50px;
  height: 5px;
  background-color: rgb(0, 9, 128);
  border-radius: 2.5px;
  margin-top: 10px;
}

.animated-paragraph {
  font-size: 30px;
  /* Adjust the font size to your preference */
  text-align: center;
}

.sample-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Center content vertically */
  padding-left: 50px;
  padding-right: 50px;
}


@media screen and (max-width: 1400px) {
  .sample-info {
    padding-bottom: 50px;
  }
}

.view-more-projects {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  font-size: 30px;
  padding-top: 50px;
}

.custom-font-4 ul li {
  color: white;
}

@media screen and (max-width: 700px) {
  .custom-font-1 .heading {
    font-size: 2.3rem;
  }

  .custom-font-1 p {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 440px) {
  .custom-font-1 .heading {
    font-size: 2rem;
  }

  .custom-font-1 p {
    font-size: 1rem;
    padding-left: 10px;
    padding-right: 10px;
  }

  .hermes::after {
    bottom: 35px;
  }

  .view::after {
    bottom: 35px;
  }
}

.container-2 h1 {
  margin-top: 0;
  font-size: 70px;
}

@media screen and (max-width: 900px) {
  .container-2 h1 {
    margin-top: 0;
    font-size: 50px;
  }

  .sample-container h2 {
    color: rgb(0, 9, 128);
    font-size: 50px;
  }

  .container-4 h2 {
    color: white;
    font-size: 50px;
  }
}

@media screen and (max-width: 450px) {
  .container-2 h1 {
    margin-top: 0;
    font-size: 40px;
  }

  .sample-container h2 {
    color: rgb(0, 9, 128);
    font-size: 40px;
  }

  .container-4 h2 {
    color: white;
    font-size: 40px;
  }
}






#testimonials {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#testimonials h2 {
  font-size: 3rem;
  color: black;
}

#testimonials h2::after {
  background-color: transparent;
}

.testimonials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-container {
  display: flex;
  align-items: center;
  position: relative;
}

.carousel-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 50%;
}

.carousel-control-prev-icon {
  background-color: black;
  border-radius: 50%;
}

.carousel-control-next-icon {
  background-color: black;
  border-radius: 50%;
}

.container .carousel-control-prev {
  left: -180px;
}

.container .carousel-control-next {
  right: -180px;
}

.carousel-button.left {
  left: 0;
}

.carousel-button.right {
  right: 0;
}

.testimonials-grid {
  display: flex;
  overflow: hidden;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}

.testimonial-item {
  flex: 0 0 32%;
  margin: 10px;
  transition: transform 0.5s ease-in-out;
}

@media (max-width: 1650px) {

  .testimonials-grid {
    display: flex;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    gap: 20px;
  }

  .container .carousel-control-prev {
    left: 0;
  }

  .container .carousel-control-next {
    right: 0;
  }
}