.privacy-policy-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    color: #333;
}

.privacy-policy-container h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.privacy-policy-container h2 {
    font-size: 1.5em;
    margin-top: 20px;
}

.privacy-policy-container ul {
    list-style-type: disc;
    margin-left: 20px;
}

.privacy-policy-container p {
    margin-bottom: 15px;
}
