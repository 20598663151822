/* src/styles/Testimonial.css */
.testimonial {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 40%;
    height: 250px;
}

.testimonial-content {
    text-align: start;
    margin-top: 15px;
}

.testimonial-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.author-info {
    display: flex;
    align-items: center;
}

.profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.profile-pic-prompt {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #666;
    margin-right: 10px;
    text-decoration: solid;
}

.author-name {
    font-weight: bold;
}

.author-meta {
    display: flex;
    align-items: center;
}

.testimonial-date {
    font-size: 0.9rem;
    color: #666;
    margin-right: 10px;
}

.stars {
    font-size: 1.2rem;
    color: gold;
}

.star {
    margin-right: 2px;
}